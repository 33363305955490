import { get, post, put, Delete } from "../axios";

// 轮播列表-列表
export const bannerManageList = (params) =>
  get("/web/banner/manage/list", params);

// 轮播列表-新增
export const bannerManageAdd = (params) =>
  post("/web/banner/manage/add", params);

// 轮播列表-编辑
export const bannerManageUpdate = (params) =>
  put("/web/banner/manage/update", params);

// 轮播列表-删除
export const bannerManageDelete = (id) =>
  Delete("/web/banner/manage/delete/" + id, "");

// 轮播列表-状态编辑
export const bannerManageStateUpdate = (params) =>
  put("/web/banner/manage/state/update", params);
